<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Depósito via TED</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <div class="card">
          <div class="card-body" v-if="!docsOk">
            <div class="row">
              <div class="col-md-12 text-center">
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-md">Antes de fazer um depósito via TED, é necessário verificar a conta, completando o seu cadastro.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-12 text-center">
                <router-link to="/pages/user" class="btn btn-primary">
                  <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <card header-classes="bg-transparent" body-classes="px-lg-7"
                          class="card-pricing border-0 text-center mb-4">

                      <!--Header-->
                      <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Faça uma TED e recarregue sua
                        conta!</h4>

                      <!--Body-->
                      <div class="display-2">R$ {{serviceFee}}</div>
                      <span class=" text-muted">por recarga</span>
                      <ul class="list-unstyled my-4">
                        <li>
                          <div class="d-flex text-left">
                            <div>
                              <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                <i class="fas fa-arrow-right"></i>
                              </div>
                            </div>
                            <div>
                              <span class="pl-2 text-sm">Deposite qualquer valor em sua conta realizando uma simples TED.</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex text-left">
                            <div>
                              <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                <i class="fas fa-arrow-right"></i>
                              </div>
                            </div>
                            <div>
                              <span class="pl-2 text-sm">Para recarregar, envie uma TED a partir de outra conta que você seja o titular.</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex text-left">
                            <div>
                              <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                <i class="fas fa-arrow-right"></i>
                              </div>
                            </div>
                            <div>
                              <span class="pl-2 text-sm">Apenas TEDs cujo titular de origem seja você mesmo <br/><b>(CPF/CNPJ: {{socialNumberDisplay}})</b>, serão processadas.</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex text-left">
                            <div>
                              <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                <i class="fas fa-arrow-right"></i>
                              </div>
                            </div>
                            <div>
                              <span class="pl-2 text-sm">Serviço isento de tarifa e sem limites de horários.</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex text-left">
                            <div>
                              <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                <i class="fas fa-arrow-right"></i>
                              </div>
                            </div>
                            <div>
                              <span class="pl-2 text-sm">O tempo de compensação dos valores depende do tempo de processamento e envio do banco de origem.</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </card>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <card header-classes="bg-transparent" body-classes="px-lg-7"
                      class="card-pricing border-0 text-center mb-auto">

                  <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Utilize os dados abaixo para
                    fazer uma ted em sua conta:</h4>

                  <ul class="list-group text-center" style="padding: 10px 5px !important;">
                    <li class="list-group-item" style="padding: 5px 5px !important;"><b>NOME:</b> Olimpo Pay</li>
                    <li class="list-group-item" style="padding: 5px 5px !important;"><b>CNPJ:</b> 36.396.690/0001-97
                    </li>
                    <li class="list-group-item" style="padding: 5px 5px !important;"><b>BANCO:</b> 218 - Banco BS2</li>
                    <li class="list-group-item" style="padding: 5px 5px !important;"><b>AG:</b> 0001</li>
                    <li class="list-group-item" style="padding: 5px 5px !important;"><b>C/C:</b> 512773-4</li>
                  </ul>
                  <div>
                    <span class="pl-2 text-sm">* Apenas TEDs cujo titular de origem seja você mesmo <br/><b>(CPF/CNPJ: {{socialNumberDisplay}})</b>, serão processadas.</span>
                  </div>
                </card>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="border-0 card-header">
                    <h3 class="mb-0">Histórico</h3>
                  </div>
                  <el-table :data="tableData"
                            row-key="id"
                            header-row-class-name="thead-light"
                            style="width: 100%"
                            empty-text="Nenhuma transação encontrada.">
                    <el-table-column :min-width="135" label="Valor">
                      <template slot-scope="props">
                        <span>R$ {{toMoney(props.row.value)}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="190" label="CPF/CNPJ Depositante">
                      <template slot-scope="props">
                        <span>{{props.row.sourceAccountOwnerSocialNumber}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="190" label="Nome do Depositante">
                      <template slot-scope="props">
                        <span>{{props.row.sourceAccountOwnerName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="156" label="Data">
                      <template slot-scope="props">
                        <span>{{props.row.date}}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div slot="footer"
                       class="card-footer d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                      <p class="card-category small">
                        Exibindo de {{ pagination.fromNumber}} a {{ pagination.toNumber }} - Total
                        {{pagination.totalNumber }} registros
                      </p>
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.totalNumber"
                      v-on:input="loadData">
                    </base-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Option, Select, Table, TableColumn} from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import {BasePagination} from '@/components';
  import clientPaginationMixin from '../../util/tables/PaginatedTables/clientPaginationMixin'
  import {failWs, postWs} from '@/ws.service'
  import {isNullOrEmpty, moneyToFloat, toMoney} from "@/util/core.utils"
  import {VMoney} from 'v-money'

  export default {
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        usernameDisplay: '',
        socialNumberDisplay: '',
        docsOk: false,
        serviceFee: '0,00',
        search: {
          status: 'PROCESSED'
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    created() {
      this.docsOk = localStorage.getItem("docsOk") == 'true'
      if (!this.docsOk) {
        return
      }

      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.socialNumberDisplay = localStorage.getItem("socialNumberDisplay")
    },
    mounted() {
      this.findHistory();
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      findHistory() {
        this.pagination.currentPage = 1
        this.loadDataHistory()
      },
      loadDataHistory() {
        postWs("/ted-deposit/get-list-user",
          true, null,
          {
            status: this.search.status,
            pageNumber: this.pagination.currentPage,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      }
    },
    directives: {money: VMoney}
  };
</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
